import React from "react"
import styled from "styled-components"
import "./layout.css"
import { Flex } from "rebass"

const LayoutContainer = styled(Flex)`
  width: 100%;
  height: 100vh;
  padding: 0 1rem;
`

const Layout = ({ children }) => {
  return (
    <>
      <LayoutContainer
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {children}
      </LayoutContainer>
    </>
  )
}

export default Layout
